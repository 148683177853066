import React, { useState, useEffect } from "react";
import {
  BaseButtons,
  TextInputField,
} from "../../../../../../../../../../components/atoms";

const LineItemCard = ({ data, statusCheck, EditQty, index, onRemoveClick }) => {
  const [qty, setQty] = useState(null);
  const [baseQty, setBaseQty] = useState(null);

  const [isSaved, setIsSaved] = useState(null);

  const NameAssemble = ({ product }) => {
    if (product) {
      let name =
        product.product.productName +
        " | " +
        product.product.productSubType +
        " | " +
        product.product.thcType +
        " | " +
        product.product.thcContent;
      return name;
    }
  };
  useEffect(() => {
    if (data) {
      setQty(data.qtyCard.qty);
      setBaseQty(data.qtyCard.qty);
    }
  }, [data]);
  return (
    <div
      style={{
        width: "100%",
        height: 85,
        borderRadius: 10,
        overflow: "hidden",
        borderStyle: "solid",
        borderWidth: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
      }}
    >
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={data.product.imageUrl}
          alt="product"
          style={{ height: "100%" }}
        />
      </div>
      <div style={{ marginLeft: 20 }}>{NameAssemble({ product: data })}</div>
      <div style={{ flex: 1 }} />
      <TextInputField
        type={"number"}
        labelTitle="QTY"
        invert={true}
        mini={true}
        height={20}
        marginTop={-5}
        value={qty}
        onChange={(e) => setQty(e.target.value)}
        readOnly={!statusCheck}
      />
      <div style={{ minWidth: 20 }} />
      {statusCheck ? (
        <BaseButtons
          label={isSaved ? "EDIT" : "SAVE EDIT"}
          mini={true}
          borderRadius={8}
          height={40}
          marginTopFalse={true}
          marginRight={20}
          onClick={
            isSaved
              ? () => setIsSaved(false)
              : () => {
                  EditQty({ idIndex: index, qty: qty });
                  setIsSaved(true);
                }
          }
          disable={qty !== baseQty ? false : true}
        />
      ) : null}

      {statusCheck ? (
        <BaseButtons
          label={"REMOVE"}
          mini={true}
          borderRadius={8}
          height={40}
          marginTopFalse={true}
          marginRight={20}
          onClick={onRemoveClick}
        />
      ) : null}
    </div>
  );
};

export default LineItemCard;
