import React, { useState, useEffect } from "react";
import { BlackAndWhite } from "../../../../../../themes/Colors";
import {
  BaseButtons,
  CloseButton,
  TextInputFieldSearch,
} from "../../../../../../components/atoms";
import { ProductListHeader } from "../../../product-uploader/components/list-view/components";
import ProductListCard from "../../../product-uploader/components/list-view/components/product-list-card/ProductListCard";

const CreateTransferPopUp = ({
  display,
  setTransferCreate,
  auth,
  setMultiRecieveArrayItem,
  setToastPop,
  setMessage,
}) => {
  const [storesArray, setStoresArray] = useState(null);
  const [selectedStore, setSelectedStore] = useState(null);

  const [stateCheck, setStateCheck] = useState([]);

  let transferList = [];

  const [definedList, setDefinedList] = useState([]);

  const [search, setSearch] = useState("");

  const [productList, setProductList] = useState(null);

  const [qtyArray, setqtyArray] = useState([]);

  const removeSelectedItemsQty = (e, index) => {
    var array = [...qtyArray]; // make a separate copy of the array

    array.splice(index, 1);
    setqtyArray(array);
  };
  const AddNewItemToArrayQty = (product) => {
    let baseArray = qtyArray;
    // qtyArray.push(product);
    baseArray.push(product);
    setStateCheck(stateCheck ? false : true);
  };

  const ReturnArrayQtu = () => {
    return qtyArray;
  };

  let productsArray = [];
  let productsArrayCheck = [];

  const [facilitiesArray, setFacilitiesArray] = useState(null);
  const AddNewItemToArray = (product) => {
    let baseArray = productsArrayCheck;
    definedList.push(product);
    baseArray.push(product);
    setStateCheck(stateCheck ? false : true);
  };

  const ReturnArray = () => {
    return definedList;
  };

  const removeSelectedItems = (e, index) => {
    var array = [...definedList]; // make a separate copy of the array

    array.splice(index, 1);
    setDefinedList(array);
  };
  const getProducts = async () => {
    // setLoading(true);
    // authentication or login function heal
    //this code is used for Ui test
    // https://merry-jane-api.onrender.com/cannaPos/reg

    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/productUpload/getProductsAdded",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const respnseData = await response.json();
      if (respnseData.product) {
        // const wait = await updateListFetch();
        setProductList(respnseData.product);
        // setLoading(false);
      } else if (respnseData.message) {
        setProductList([]);
        // setLoading(false);
      }
    } catch (err) {
      console.log("err", err);
      // setLoading(false);
    }
  };

  const NameAssemble = (product) => {
    if (product) {
      let name =
        product.productName +
        " | " +
        product.productSubType +
        " | " +
        product.thcType +
        " | " +
        product.thcContent;
      return name;
    }
  };

  const WorkOutMargin = (product) => {
    const productBaseMargin =
      Number(product.costPrice) - Number(product.baseCostPrice);
    return productBaseMargin.toFixed(2);
  };

  const CreateTransferDoc = async () => {
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/inventory/createTransferDoc",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            recievingStockProducts: qtyArray,
            createdBy: auth.firstName + " " + auth.lastName,
            storeId: selectedStore._id,
          }),
        }
      );

      const responseData = await response.json();

      if (responseData.success) {
        setqtyArray([]);
        setTransferCreate(false);
        setDefinedList([]);
        setMessage(responseData.success);
        setToastPop(true);
        setSelectedStore(null);
      } else {
        setMessage(responseData.message);
        setToastPop(true);
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  useEffect(() => {
    getProducts();
    fetch("https://merry-jane-api.onrender.com/facilities/getFacilities")
      .then((response) => {
        return response.json();
      })
      .then((resData) => {
        setFacilitiesArray(resData);
      });
  }, [display]);

  useEffect(() => {
    if (definedList.length >= 1) {
      ReturnArray();
    }
    ReturnArrayQtu();
  }, [stateCheck]);
  return (
    <div
      style={{
        position: "absolute",
        bottom: 0,
        height: display ? "100%" : 0,
        width: "100%",
        backgroundColor: BlackAndWhite.secondary,
        // overflow: "hidden",
        transition: "all 0.3s",
        display: "flex",
      }}
    >
      <div style={{ position: "absolute", top: 20, left: 20 }}>
        <CloseButton
          label={"X"}
          onClick={() => {
            setTransferCreate(false);
          }}
        />
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            minHeight: 70,
          }}
        >
          <div style={{ marginLeft: 80, fontSize: 20, fontWeight: "bold" }}>
            CREATE TRANSFER
          </div>
          <div style={{ flex: 1 }} />
          {selectedStore ? (
            <div
              onClick={() => {
                setSelectedStore(null);
                setDefinedList([]);
              }}
              style={{ marginRight: 20, display: "flex", alignItems: "center" }}
            >
              <BaseButtons
                label={"CHANGE"}
                borderRadius={8}
                mini={true}
                marginTop={-2}
                height={35}
              />{" "}
              <div style={{ marginLeft: 20 }}>{selectedStore.storeName}</div>
            </div>
          ) : (
            <div
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",

                // width: 300,

                borderWidth: 0.5,
              }}
            >
              <TextInputFieldSearch
                placeholder={"TYPE HERE TO SEARCH STORE"}
                height={25}
                borderRadius={4}
                onChange={(e) => setSearch(e.target.value)}
                value={search}
              />
              <div
                style={{
                  height: 30,
                  width: 70,
                  backgroundColor: BlackAndWhite.primary,
                  borderRadius: 4,
                  display: "flex",
                  color: BlackAndWhite.secondary,
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: 20,
                }}
              >
                <div
                  onClick={() => setSearch("")}
                  style={{ fontSize: 12, fontWeight: "bold" }}
                >
                  CLEAR
                </div>
              </div>
            </div>
          )}
          <BaseButtons
            mini={true}
            label={"SUMBIT TRANSFER"}
            borderRadius={8}
            marginTopFalse={true}
            height={35}
            size={180}
            disable={qtyArray.length === definedList.length ? false : true}
            onClick={() => CreateTransferDoc()}
          />
          <div style={{ minWidth: 10 }} />
        </div>
        {selectedStore ? (
          <ProductListHeader
            warehouseSelect={true}
            transferDisplay={true}
            isProdycDisplay={true}
          />
        ) : null}
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            overflowY: "scroll",
            overflowX: "hidden",
            scrollbarWidth: "none",
          }}
        >
          {selectedStore ? null : (
            <div style={{ marginTop: 20, marginBottom: 20, marginLeft: 20 }}>
              {selectedStore ? null : "SELECT STORE BELOW"}
            </div>
          )}
          {selectedStore ? (
            <>
              {definedList.length >= 1 ? (
                <div style={{ width: "100%", textAlign: "left" }}>
                  <div
                    style={{
                      marginLeft: 10,
                      fontWeight: "bold",
                      marginTop: 10,
                      marginBottom: 10,
                    }}
                  >
                    RECIEVING LIST
                  </div>
                </div>
              ) : null}
              {definedList.map((product, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      width: "100%",
                      minHeight: 55,
                      // borderStyle: "solid",
                    }}
                  >
                    <ProductListCard
                      transferDisplay={true}
                      warehouseSelect={true}
                      productName={product.productName}
                      image={product.imageUrl}
                      product={product}
                      sku={product.sku}
                      unitMeasure={product.quantityDenomiation}
                      subCat={product.productSubType}
                      size={product.size}
                      thcType={product.thcType}
                      marginPrice={product ? WorkOutMargin(product) : "loading"}
                      category={product.productType}
                      productPref={product.flavour}
                      productFeature={product.strain}
                      retail={product.retailPriceInc}
                      removeSelectedItemsQty={removeSelectedItemsQty}
                      onRemoveClick={() => {
                        removeSelectedItems(product, index);
                        removeSelectedItemsQty(product, index);
                      }}
                      auth={auth}
                      // AddNewItemToArrayQty={AddNewItemToArrayQty}
                      AddNewItemToArray={AddNewItemToArrayQty}
                    />
                  </div>
                );
              })}
              {selectedStore ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginLeft: 20,

                      // width: 300,

                      borderWidth: 0.5,
                      marginTop: 15,
                      marginBottom: 15,
                    }}
                  >
                    <TextInputFieldSearch
                      placeholder={"Type here to search"}
                      height={25}
                      borderRadius={4}
                      onChange={(e) => setSearch(e.target.value)}
                      value={search}
                    />
                    <div
                      style={{
                        height: 30,
                        width: 70,
                        backgroundColor: BlackAndWhite.primary,
                        borderRadius: 4,
                        display: "flex",
                        color: BlackAndWhite.secondary,
                        justifyContent: "center",
                        alignItems: "center",
                        marginRight: 20,
                      }}
                    >
                      <div
                        onClick={() => setSearch("")}
                        style={{ fontSize: 12, fontWeight: "bold" }}
                      >
                        CLEAR
                      </div>
                    </div>
                  </div>
                  <div style={{ flex: 1 }} />

                  <div style={{ minWidth: 20 }} />
                </div>
              ) : null}
              {productList
                ? productList.map((product, index) => {
                    return NameAssemble(product)
                      .toLocaleLowerCase()
                      .match(search) ? (
                      <div
                        key={index}
                        style={{
                          width: "100%",
                          marginBottom:
                            index === productList.length - 1 ? 60 : 0,
                        }}
                      >
                        <ProductListCard
                          isProduct={true}
                          warehouseSelect={true}
                          productName={NameAssemble(product)}
                          image={product.imageUrl}
                          sku={product.sku}
                          unitMeasure={product.quantityDenomiation}
                          subCat={product.productSubType}
                          size={product.size}
                          thcType={product.thcType}
                          marginPrice={
                            product ? WorkOutMargin(product) : "loading"
                          }
                          category={product.productType}
                          productPref={product.flavour}
                          productFeature={product.strain}
                          retail={product.retailPriceInc}
                          onSelectClick={() => AddNewItemToArray(product)}
                        />
                      </div>
                    ) : null;
                  })
                : null}
            </>
          ) : facilitiesArray ? (
            facilitiesArray.store.map((store, index) => {
              return store.storeName.toLocaleLowerCase().includes(search) ? (
                <div
                  onClick={() => setSelectedStore(store)}
                  key={index}
                  style={{
                    width: "100%",
                    minHeight: 70,
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 10,
                    borderBottomStyle: "solid",
                    borderBottomWidth: 1,
                    borderColor: "#00000050",
                    cursor: "pointer",
                  }}
                >
                  <div
                    style={{
                      flex: 0.3,
                      overflow: "hidden",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      fontSize: 14,
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        marginBottom: 5,
                        fontSize: 12,
                        marginLeft: 20,
                      }}
                    >
                      STORE NAME
                    </div>
                    <div style={{ marginLeft: 20 }}>{store.storeName}</div>
                  </div>
                  <div
                    style={{
                      flex: 0.1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      fontSize: 14,
                      flexDirection: "column",
                    }}
                  >
                    <div style={{ marginBottom: 5, fontSize: 12 }}>STATUS</div>
                    {store.status}
                  </div>
                  <div
                    style={{
                      flex: 0.15,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      fontSize: 14,
                      flexDirection: "column",
                    }}
                  >
                    <div style={{ marginBottom: 5, fontSize: 12 }}>
                      CREATED ON
                    </div>
                    {store.createdDate}
                  </div>
                  <div
                    style={{
                      flex: 0.3,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      fontSize: 14,
                      flexDirection: "column",
                    }}
                  >
                    <div style={{ marginBottom: 5, fontSize: 12 }}>EMAIL</div>
                    {store.email}
                  </div>
                  <div
                    style={{
                      flex: 0.2,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      fontSize: 14,
                      flexDirection: "column",
                    }}
                  >
                    <div style={{ marginBottom: 5, fontSize: 12 }}>CELL</div>
                    {store.cellNumber}
                  </div>
                </div>
              ) : null;
            })
          ) : null}
          {/* select store

            then select products

            check quantities then crate transfer */}
        </div>
      </div>
    </div>
  );
};

export default CreateTransferPopUp;
